/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  ModelLevel,
  QuestionType,
  TestTag,
  TestType,
} from '@unione-pro/unione.assmnt.sdk.webapp';

declare enum FieldsDisplayName {
  show_academic_hours = 'show_academic_hours',
  show_academic_months = 'show_academic_months',
  show_description = 'show_description',
  show_it = 'show_it',
  show_docs = 'show_docs',
  show_branch_industry = 'show_branch_industry',
  show_qualification_name = 'show_qualification_name',
  show_year = 'show_year',
}

export const QUESTION_TAG_SELECT_DISABLED = {
  [QuestionType.one]: false,
  [QuestionType.case]: true,
  [QuestionType.plural]: false,
  [QuestionType.matrix]: true,
  [QuestionType.tree]: true,
  [QuestionType.open]: true,
};

export const TAG_BY_TEST_TYPE = {
  [TestType.competency]: {
    [QuestionType.one]: TestTag.competency_level,
    [QuestionType.case]: TestTag.competency,
    [QuestionType.plural]: TestTag.competency,
    [QuestionType.matrix]: TestTag.competency,
    [QuestionType.tree]: TestTag.competency,
    [QuestionType.open]: TestTag.competency,
  },
  [TestType.sphere]: {
    [QuestionType.one]: TestTag.sphere,
    [QuestionType.case]: TestTag.sphere,
    [QuestionType.plural]: TestTag.sphere,
    [QuestionType.matrix]: TestTag.sphere,
    [QuestionType.tree]: TestTag.sphere,
    [QuestionType.open]: TestTag.sphere,
  },
};

export const LEVEL_WEIGHT_BY_TEST_LEVEL = {
  [ModelLevel.normalLevel]: 1,
  [ModelLevel.advancedLevel]: 2,
  [ModelLevel.expertLevel]: 3,
};

const LEVELS_RANGES = {
  [ModelLevel.normalLevel]: [0, 17, 18, 100],
  [ModelLevel.advancedLevel]: [0, 17, 18, 43, 44, 100],
  [ModelLevel.expertLevel]: [0, 17, 18, 43, 44, 79, 80, 100],
};

const [
  basicLevelFrom,
  basicLevelTo,
  normalLevelFrom,
  normalLevelTo,
  advancedLevelFrom,
  advancedLevelTo,
  expertLevelFrom,
  expertLevelTo,
] = LEVELS_RANGES[ModelLevel.expertLevel];

export const LEVEL_RANGES_BY_TEST_LEVEL = {
  basic_level: {
    from: basicLevelFrom,
    to: basicLevelTo,
  },
  [ModelLevel.normalLevel]: {
    from: normalLevelFrom,
    to: normalLevelTo,
  },
  [ModelLevel.advancedLevel]: {
    from: advancedLevelFrom,
    to: advancedLevelTo,
  },
  [ModelLevel.expertLevel]: {
    from: expertLevelFrom,
    to: expertLevelTo,
  },
};

export const QUESTION_WEIGHT_BY_QUESTION_TYPE = {
  [QuestionType.one]: 1,
  [QuestionType.case]: 2,
  [QuestionType.plural]: 1,
  [QuestionType.matrix]: 2,
  [QuestionType.tree]: 2,
  [QuestionType.open]: 1,
};

export const TIME_FOR_QUESTION_BY_QUESTION_TYPE = {
  [QuestionType.one]: 2,
  [QuestionType.case]: 2,
  [QuestionType.plural]: 10,
  [QuestionType.matrix]: 2,
  [QuestionType.tree]: 2,
  [QuestionType.open]: 10,
};

export const FIELDS_DISPLAY_LABELS: Record<string, string> = {
  show_academic_hours: 'Часы',
  show_academic_months: 'Месяцы',
  show_description: 'Описание',
  show_it: 'ИТ / не ИТ',
  show_docs: 'Документы',
  show_branch_industry: 'Отраслевая принадлежность',
  show_qualification_name: 'Наименование квалификации',
  show_year: 'Поток',
};

export const CHIP_CUSTOM_COLOR = '#07B4CF';
const TEST_OPTIONS = 'test_options';

export const LEVELS_FIELD_NAME = 'levels';
export const WEIGHT_QUESTIONS_FIELD_NAME = `${TEST_OPTIONS}.weight_questions`;
export const WEIGHT_LEVELS_FIELD_NAME = `${TEST_OPTIONS}.weight_levels`;
export const TEST_TYPE_FIELD_NAME = `${TEST_OPTIONS}.test_type`;
export const QUESTION_OUTPUT_FIELD_NAME = `${TEST_OPTIONS}.output`;
export const FIELDS_DISPLAY_FIELD_NAME = 'fields_display';
export const FIELDS_DISPLAY_FIELD_NAMES: Record<FieldsDisplayName, string> = {
  show_academic_hours: 'show_academic_hours',
  show_academic_months: 'show_academic_months',
  show_branch_industry: 'show_branch_industry',
  show_description: 'show_description',
  show_docs: 'show_docs',
  show_it: 'show_it',
  show_qualification_name: 'show_qualification_name',
  show_year: 'show_year',
};
