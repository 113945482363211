import { FC, ReactElement, useEffect } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import {
  ICourseModel,
  RegistrationType,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import Switch from 'rc-switch';
import {
  FIELDS_DISPLAY_FIELD_NAME,
  FIELDS_DISPLAY_FIELD_NAMES,
  FIELDS_DISPLAY_LABELS,
} from '../../../../constants/model.constants';
import { useFormCommonStyles } from '../use-styles';
import { useFieldsStyles } from './use-styles';

export const FieldsDisplay: FC = () => {
  const commonClasses = useFormCommonStyles();
  const fieldClasses = useFieldsStyles();

  const form = useForm();
  const { input } = useField(FIELDS_DISPLAY_FIELD_NAME);
  const { values }: { values: ICourseModel } = useFormState();

  const showYearValue = values.fields_display.show_year;

  useEffect(() => {
    form.change(`${FIELDS_DISPLAY_FIELD_NAME}.${FIELDS_DISPLAY_FIELD_NAMES.show_branch_industry}`, showYearValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showYearValue]);

  const isListRegistrationType
    = values.common_params.registration_type === RegistrationType.list;

  const fields = Object.keys(input.value).filter(
    (field) => field !== 'show_questions',
  );

  return (
    <Paper className={commonClasses.paper}>
      <h3 className={commonClasses.blockTitle}>Отображение полей</h3>

      <div className={fieldClasses.fields}>
        {fields.map((fieldName) => (
          <Field
            key={fieldName}
            name={`${FIELDS_DISPLAY_FIELD_NAME}.${fieldName}`}
            render={({ input: { value, onChange } }): ReactElement => (
              <label className={fieldClasses.field}>
                <div className={commonClasses.secondary}>
                  {FIELDS_DISPLAY_LABELS[fieldName]}
                </div>
                <Switch
                  onChange={onChange}
                  checked={value}
                  disabled={fieldName === 'show_docs' && isListRegistrationType}
                  className={commonClasses.switcher}
                />
              </label>
            )}
          />
        ))}
      </div>
    </Paper>
  );
};
